import React, { useState } from 'react';
import Soundboard from './soundboard';
import '../css/controls.css';
import axios from 'axios';
import FormAlert from './form-alert';
import Slider from './slider';
import ToggleSwitch from './toggle-switch';

const Controls = ({ updateData, user, ...props }) => {
  const initialFormState = { ...props };
  const [formData, setFormData] = useState(initialFormState);
  const muted = false;
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });

  const skipMessage = async () => {
    try {
      await axios.get('/api/skip');
      triggerAlert('Chat skipped');
    } catch (err) {
      console.error(err);
    }
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const handleToggle = (name) => {
    const value = formData[name] ? false : true;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSlider = (evt) => {
    let value = evt.target.valueAsNumber;
    if (evt.target.name == 'ttsVolume' || evt.target.name == 'ttsChatsVolume') {
      value = Math.round(value);
    }
    setFormData({
      ...formData,
      [evt.target.name]: value,
    });
  };

  const resetForm = () => {
    setFormData(initialFormState);
    triggerAlert('Changes discarded');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axios.put('/api/mediacontrols', formData);
      if (data === 'OK') {
        updateData(formData);
        triggerAlert('Control settings updated');
      } else {
        triggerAlert('Could not save controls', false);
      }
    } catch (err) {
      console.error(err);
      triggerAlert('Could not save controls', false);
    }
  };

  return (
    <div className="parent-container">
    <div className="controls-container form-container">
      <button className="skip-btn secondary-btn" onClick={skipMessage}>
        Skip Message
      </button>
      <form onSubmit={handleSubmit}>
        <div className="toggle-switch-row flex-form-row">
          <ToggleSwitch
            name="mediaEnabled"
            onChange={() => handleToggle('mediaEnabled')}
            checked={formData.mediaEnabled}
          />
          <label htmlFor="mediaEnabled">Media Enabled</label>
        </div>
        <div className="toggle-switch-row flex-form-row">
          <ToggleSwitch
            name="playbackEnabled"
            onChange={() => handleToggle('playbackEnabled')}
            checked={formData.playbackEnabled}
          />
          <label htmlFor="playbackEnabled">Playback Enabled</label>
        </div>
        <div>
          <Slider name="playbackDelay"
            label="Delay Between Playbacks"
            value={formData.playbackDelay}
            min={0}
            max={10}
            step={1}
            unit="s"
            handleChange={handleSlider}
            list="playbackDelayMarkers" />
          <datalist id="playbackDelayMarkers">
            {[...Array(11).keys()].map(i => (
              <option key={"playbackDelay" + i} value={i} />
            ))}
          </datalist>
        </div>
        <div>
          <Slider name="ttsVolume"
            label="Volume"
            min={0}
            max={100}
            step={0.2}
            unit="%"
            value={formData.ttsVolume}
            handleChange={handleSlider}
            disabled={muted} />
        </div>
        <div className="toggle-switch-row flex-form-row">
          <ToggleSwitch
            name="ttsChats"
            onChange={() => handleToggle('ttsChats')}
            checked={formData.ttsChats}
          />
          <label htmlFor="ttsChats">Enable live chat TTS</label>
        </div>
        <div>
          <Slider name="ttsChatsVolume"
            label="Live Chat TTS Volume"
            min={0}
            max={100}
            step={10}
            unit="%"
            value={formData.ttsChatsVolume}
            handleChange={handleSlider}
            disabled={!formData.ttsChats}
            list="chatsVolumeMarkers" />
          <datalist id="chatsVolumeMarkers">
            {[...Array(11).keys()].map(i => (
              <option key={"chatVolume" + i} value={10*i} />
            ))}
          </datalist>
        </div>
        <button type="submit">Save</button>
        <button
          type="reset"
          className="secondary-btn cancel-btn"
          onClick={resetForm}
        >
          Cancel
        </button>
      </form>
      <FormAlert alertState={alertState} width={400} />
    </div>
    <Soundboard user={user} />
    </div>
  );
};

export default Controls;
