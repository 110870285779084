import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faSquare,
  faEdit,
  faTimes,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import '../css/moderation.css';
import Card from '../components/card';
import { sortAlphabetically } from '../constants';
import Search from '../components/search';
import FormAlert from '../components/form-alert';

const Moderation = () => {
  const [initialUsers, setInitialUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [deletedAccounts, setDeletedAccounts] = useState([]);
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const getAllUsers = async () => {
    try {
      const { data } = await axios.get('/api/users');
      setInitialUsers(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    setUsers(initialUsers);
  }, [initialUsers]);

  const searchFilterCallback = ({ username, email }, searchInput) =>
    username.includes(searchInput) || email.includes(searchInput);

  const enableEditMode = () => {
    setEditModeEnabled(true);
  };

  const togglePlatformEnabled = async (userId, platform) => {
    let updatedUsers = [];
    const userRow = users.find(({ id }) => id === userId);
    try {
      updatedUsers = users.map((user) => {
        if (userRow.id === user.id) {
          user[platform] = !user[platform];
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (err) {
      console.error(err);
    }
  };

  const updateDeletedAccounts = (userId) => {
    const updatedDeletedAccounts = [...deletedAccounts, userId];
    setDeletedAccounts(updatedDeletedAccounts);
  };

  const deleteUser = (userId) => {
    const updatedUsers = users.filter((user) => user.id !== userId);
    setUsers(updatedUsers);
    updateDeletedAccounts(userId);
  };

  const logoutUser = async (username, userId) => {
    try {
      let result = confirm(`Terminate all sessions for '${username}'? This action will be taken immediately.`);
      if (result) {
        await axios.post('/api/logout-user', { userId });
        triggerAlert(`Logged out user '${username}'`);
      }
    } catch (err) {
      console.error(err);
      triggerAlert('Could not logout user', false);
    }
  };

  const getModerationSettings = () => {
    const moderationSettings = users.map(
      ({ id, paypal_legacy, robotstreamer_enabled, cozytv_enabled }) => {
        const userModerationSettings = {
          id,
          paypal_legacy,
          robotstreamer_enabled,
          cozytv_enabled,
        };
        return userModerationSettings;
      }
    );
    return moderationSettings;
  };

  const saveModerationSettings = async () => {
    const moderationSettings = getModerationSettings();
    try {
      await axios.post('/api/moderation', {
        moderationSettings,
        deletedAccounts,
      });
      triggerAlert('Saved moderation settings');
    } catch (err) {
      console.error(err);
      triggerAlert('Could not save moderation settings', false);
    }
    setEditModeEnabled(false);
  };

  const cancel = () => {
    setEditModeEnabled(false);
    getAllUsers();
  };

  return (
    <div className="moderation-container page-container">
      <Card title="Moderation" lineClass="moderation-line">
        <div>
          <div className="search-wrapper">
            <Search
              inputPlaceholder="Enter email or username"
              initialData={initialUsers}
              data={users}
              setData={setUsers}
              filterCallback={searchFilterCallback}
            />
            <div className="edit-btn-container">
              <FontAwesomeIcon
                icon={faEdit}
                color="#57bad8"
                size="2x"
                onClick={enableEditMode}
              />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th className="text-center">Paypal</th>
                <th className="text-center">RobotStreamer</th>
                <th className="text-center">Cozy.tv</th>
                <th className="text-center">Logout</th>
                <th className="text-center">Delete</th>
              </tr>
            </thead>
            <tbody>
              {users
                .sort(sortAlphabetically)
                .map(
                  (
                    {
                      id,
                      username,
                      email,
                      paypal_legacy,
                      robotstreamer_enabled,
                      cozytv_enabled,
                    },
                    idx
                  ) => (
                    <tr key={username + String(idx)}>
                      <td>{username}</td>
                      <td>{email}</td>
                      <td className="text-center">
                        <button
                          className="secondary-btn"
                          disabled={!editModeEnabled}
                          onClick={() =>
                            togglePlatformEnabled(id, 'paypal_legacy')
                          }
                        >
                          {paypal_legacy ? (
                            <FontAwesomeIcon icon={faCheckSquare} size="xl" />
                          ) : (
                            <FontAwesomeIcon icon={faSquare} size="xl" />
                          )}
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="secondary-btn"
                          disabled={!editModeEnabled}
                          onClick={() =>
                            togglePlatformEnabled(id, 'robotstreamer_enabled')
                          }
                        >
                          {robotstreamer_enabled ? (
                            <FontAwesomeIcon icon={faCheckSquare} size="xl" />
                          ) : (
                            <FontAwesomeIcon icon={faSquare} size="xl" />
                          )}
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="secondary-btn"
                          disabled={!editModeEnabled}
                          onClick={() =>
                            togglePlatformEnabled(id, 'cozytv_enabled')
                          }
                        >
                          {cozytv_enabled ? (
                            <FontAwesomeIcon icon={faCheckSquare} size="xl" />
                          ) : (
                            <FontAwesomeIcon icon={faSquare} size="xl" />
                          )}
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          disabled={!editModeEnabled}
                          onClick={() => logoutUser(username, id)}
                        >
                          <FontAwesomeIcon
                            icon={faPowerOff}
                            size="lg"
                            color="#57bad8"
                          />
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          disabled={!editModeEnabled}
                          onClick={() => deleteUser(id)}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            size="lg"
                            color="#57bad8"
                          />
                        </button>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
          {editModeEnabled && (
            <div className="action-btn-container">
              <button onClick={saveModerationSettings}>Save</button>
              <button className="secondary-btn" onClick={cancel}>
                Cancel
              </button>
            </div>
          )}
          <FormAlert alertState={alertState} width={400} />
        </div>
      </Card>
    </div>
  );
};

export default Moderation;
