import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import '../css/navbar.css';
import useAuth from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ onClickOutside }) => {
  const { user, userAvatar, isAdmin, logout } = useAuth();
  const location = useLocation();
  const [userDropdownIsVisible, setUserDropdownIsVisible] = useState(false);
  const [mobileNavIsVisible, setMobileNavIsVisible] = useState(false);

  const userMenuRef = useRef(null);
  const toggleMenuRef = useRef(null);

  const defaultUserPhotoURL = 'user-default.jpg';

  const toggleUserDropdown = () => {
    if (mobileNavIsVisible && !userDropdownIsVisible) {
      setMobileNavIsVisible(false);
    }
    setUserDropdownIsVisible(!userDropdownIsVisible);
  };

  const logoutUser = () => {
    logout();
    setUserDropdownIsVisible(false);
  };

  const toggleMobileNav = () => {
    if (userDropdownIsVisible && !mobileNavIsVisible) {
      setUserDropdownIsVisible(false);
    }
    setMobileNavIsVisible(!mobileNavIsVisible);
  };

  const handleResize = () => {
    if (window.innerWidth > 800) {
      setMobileNavIsVisible(false);
    }
  };

  const handleRouteChange = () => {
    setMobileNavIsVisible(false);
    setUserDropdownIsVisible(false);
  };

  useEffect(() => {
    handleRouteChange();
  }, [location]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userMenuRef.current &&
        !userMenuRef.current.contains(event.target) &&
        toggleMenuRef.current &&
        !toggleMenuRef.current.contains(event.target)
      ) {
        setUserDropdownIsVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div className="nav-container">
      <nav>
        <div className="mobile-nav">
          <img
            src="/static/img/hamburger-menu.webp"
            onClick={toggleMobileNav}
            className="mobile-nav-icon pointer"
          />
          {mobileNavIsVisible && (
            <div className="mobile-nav-dd padding-20 shadow-lg">
              <Link to="/" id="mobile-logo">
                <img src="/static/img/powerchat-logo.webp" />
              </Link>
              <Link to="/features">Features</Link>
              <Link to="/pricing">Pricing</Link>
              {/* <Link to="/learn">Learn</Link>
              <Link to="/live">Live Now</Link> */}
              {isAdmin && <Link to="/network">Network</Link>}
            </div>
          )}
        </div>
        <div className="flex-center text-bold">
          <Link to="/">
            <img id="logo" src="/static/img/powerchat-full-text-logo.webp" />
          </Link>
          <div className="nav-links-container">
            <Link to="/features">Features</Link>
            <Link to="/pricing">Pricing</Link>
            {/* <Link to="/learn">Learn</Link>
            <Link to="/live">Live Now</Link> */}
            {isAdmin && <Link to="/network">Network</Link>}
          </div>
        </div>
        {user ? (
          <div className="user-menu">
            <div
              className="flex-center text-bold pointer"
              ref={toggleMenuRef}
              onClick={toggleUserDropdown}
            >
              <div className="crop-wrapper">
                <img src={userAvatar || `/static/img/${defaultUserPhotoURL}`} />
              </div>
              <span className="user-dd-arrow">
                <FontAwesomeIcon icon={faChevronDown} size="lg" />
              </span>
            </div>
            {userDropdownIsVisible && (
              <div ref={userMenuRef} className="user-dd padding-20 shadow-lg">
                <h4>{user.username}</h4>
                <Link to={`/${user.username}`}>Powerchat Page</Link>
                <Link to="/account-settings">Account Settings</Link>
                <Link to="/creator-dashboard">Creator Dashboard</Link>
                {isAdmin && <Link to="/moderation">Moderation</Link>}
                <button onClick={logoutUser}>Logout</button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex-center text-bold auth-links">
            <Link to="/signup">Signup</Link>
            <Link to={"/login" + (location.pathname == '/' ? '' : `?redirect=${location.pathname}`)}>Login</Link>
          </div>
        )}
      </nav>
    </div>
  );
};

export default withRouter(Navbar);
