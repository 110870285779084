import React, { useState, useEffect, useRef } from 'react';
const axios = require('axios');
import '../css/personalization.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faCirclePlus,
  faTimes,
  faPenToSquare,
  faCircleXmark,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons';
import FormAlert from './form-alert';
import ToggleSwitch from './toggle-switch';

const Personalization = ({ updateData, ...props }) => {
  const tagLineMax = 140;
  const descriptionMaxLength = 500;
  const maxLinks = 15;
  // 10 max lines
  const maxLinesRegex = /^(?:[^\r\n]*(?:\r\n?|\n)){0,10}[^\r\n]*$/;
  const dragItem = useRef();
  const dragIndex = useRef();
  const dragOverItem = useRef();
  const dragOverIndex = useRef();

  const initialFormState = {
    ...props,
  };
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [formData, setFormData] = useState(initialFormState);
  const [tooltip, setTooltip] = useState('');

  useEffect(() => {
    let links = formData.userLinks;
    links.forEach(link => {
      link['editMode'] = false;
      link['key'] = link.id;
    });
  }, []);

  const handleSubmit = async (event) => {
    const updatedSettings = Object.fromEntries(
      Object.entries(formData).filter(([,v]) => v != null)
    );
    event.preventDefault();

    let linksIncomplete = false;
    for (let link of updatedSettings.userLinks) {
      if (!link.name || link.name.trim() == '') {
        linksIncomplete = true;
      }
      if (!link.value || link.value.trim() == '') {
        linksIncomplete = true;
      }
      let url = link.value;
      if (url.substring(0, 8).toLowerCase() != 'https://' && url.substring(0, 7).toLowerCase() != 'http://') {
        link.value = 'https://' + url;
      }
    }
    if (linksIncomplete) {
      triggerAlert('Each custom link must have a title and URL', false);
      return;
    }

    try {
      const { status, data } = await axios.put('/api/personalization', {
        ...updatedSettings,
      });

      if (status == 200) {
        let updatedForm = formData;
        updatedForm.userLinks.forEach(link => {
          link.editMode = false;
          let newLink = data.newLinks.find(l => l.name == link.name);
          if (newLink) {
            link.id = newLink.id;
          }
          try {
            let uri = new URL(link.value);
            link['icon'] = `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${uri.origin}&size=32`;
          } catch(err) {
            console.error(err);
          }
        });
        setFormData(updatedForm);
        updateData(updatedForm);
        triggerAlert('Settings successfully updated');
      } else {
        triggerAlert('Could not save settings', false);
      }
    } catch (err) {
      console.error(err);
      triggerAlert('Could not save settings', false);
    }
  };

  const cancelSettingsUpdate = () => {
    setFormData({ ...initialFormState });
    triggerAlert('Changes discarded');
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name == 'tagLine' && !!value) {
      value = value.replace(/[\r\n]/ig, '');
    }
    if (name == 'description' && !!value) {
      if (value.length > descriptionMaxLength) {
        value = value.substring(value, descriptionMaxLength);
      }
      let newlineRegex = value.match(maxLinesRegex);
      if (!newlineRegex) {
        return;
      }
    }
    setFormData({
      ...formData,
      [event.target.name]: value,
    });
  };

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const addNewLink = () => {
    let links = [...formData.userLinks];
    if (links.filter(x => x.custom_link).length >= maxLinks) {
      triggerAlert('Too many custom links. Max: ' + maxLinks, false);
      return;
    }

    links.push({
      name: '',
      value: '',
      editMode: true,
      enabled: true,
      custom_link: true,
      key: 'tmp-id-' + links.length,
      rank: Math.max(...links.map(x => x.rank)) + 1,
    });
    setFormData({
      ...formData,
      userLinks: links,
    });
  };

  const toggleLink = (checked, index) => {
    setFormData({
      ...formData,
      userLinks: formData.userLinks.map((link, i) => {
        if (i == index) {
          link.enabled = checked;
        }
        return link;
      })
    });
  };

  const editLink = (index) => {
    setFormData({
      ...formData,
      userLinks: formData.userLinks.map((link, i) => {
        if (i == index) {
          link.editMode = true;
          link.origValue = link.value;
        }
        return link;
      })
    });
  };

  const cancelEditLink = (index) => {
    if (formData.userLinks[index].id) {
      setFormData({
        ...formData,
        userLinks: formData.userLinks.map((link, i) => {
          if (i == index) {
            link.editMode = false;
            link.value = link.origValue;
          }
          return link;
        }),
      });
    }
    else {
      return removeLink(index);
    }
  };

  const removeLink = async (index) => {
    let link = formData.userLinks[index];
    if (link.id) {
      if (!confirm('Remove this link?')) {
        return;
      }
      try {
        await axios.delete('/api/user-links/' + link.id);
        triggerAlert('Link removed');
      } catch (err) {
        triggerAlert('Could not remove link', false);
      }
    }
    let userLinks = [...formData.userLinks.filter((link, i) => i != index)];
    let rank = 1;
    userLinks.forEach(link => link.rank = rank++);
    setFormData({
      ...formData,
      userLinks,
    });
  };

  const onLinkTitleChange = (event, index) => {
    setFormData({
      ...formData,
      userLinks: formData.userLinks.map((link, i) => {
        if (i == index) {
          link.name = event.target.value;
        }
        return link;
      })
    });
  };

  const onLinkValueChange = (event, index) => {
    setFormData({
      ...formData,
      userLinks: formData.userLinks.map((link, i) => {
        if (i == index) {
          link.value = event.target.value;
        }
        return link;
      })
    });
  };

  const dragStart = (event, index) => {
    dragIndex.current = index;
    dragItem.current = event.target;
    event.target.classList.add('dragging');
  };

  const dragEnter = (event, index) => {
    dragOverIndex.current = index;
    if (event.target.nodeName == 'DIV' && event.target.classList.contains('display-link')) {
      if (dragOverItem.current) {
        dragOverItem.current.classList.remove('space-top');
        dragOverItem.current.classList.remove('space-bottom');
      }
      dragOverItem.current = event.target;
      let diff = formData.userLinks[index].rank - formData.userLinks[dragIndex.current].rank;
      if (diff > 0 || index == formData.userLinks.length - 1) {
        event.target.classList.add('space-bottom');
      } else {
        event.target.classList.add('space-top');
      }
    }
  };

  const drop = () => {
    let userLinks = [...formData.userLinks];
    let dragLink = userLinks[dragIndex.current];
    userLinks.splice(dragIndex.current, 1);
    userLinks.splice(dragOverIndex.current, 0, dragLink);
    dragIndex.current = null;
    dragOverIndex.current = null;
    dragItem.current.classList.remove('dragging');
    if (dragOverItem.current) {
      dragOverItem.current.classList.remove('space-top');
      dragOverItem.current.classList.remove('space-bottom');
    }
    dragOverItem.current = null;
    let rank = 1;
    userLinks.forEach(link => link.rank = rank++);
    setFormData({
      ...formData,
      userLinks,
    });
  };

  return (
    <div className="form-container personalization-container">
      <form onSubmit={handleSubmit}>
        <div className="settings-container">
          <div className="settings-section">
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="displayName">Display Name</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('bannerUrl')}
                  />
                  {tooltip === 'displayName' && (
                    <p className="tooltip shadow-lg">
                      Optional. Edit your display name on your page.
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <input
                  name="displayName"
                  placeholder="Display Name"
                  onChange={handleChange}
                  value={formData.displayName || ''}
                />
              </div>
              <div className="flex-row">
                <label htmlFor="bannerUrl">Banner Image URL</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                     onClick={() => toggleTooltip('bannerUrl')}
                  />
                  {tooltip === 'bannerUrl' && (
                    <p className="tooltip shadow-lg">
                      Optional. Display a banner image on your page.
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <input
                  name="bannerUrl"
                  placeholder="Banner Image URL"
                  onChange={handleChange}
                  value={formData.bannerUrl || ''}
                />
              </div>
                <div className="flex-row">
                  <label htmlFor="tagLine">Motto</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('tagLine')}
                    />
                    {tooltip === 'tagLine' && (
                      <p className="tooltip shadow-lg">
                        Short message that displays on your Powerchat page.
                      </p>
                    )}
                  </div>
                </div>
                <div className="tooltip-input">
                <input
                  className="tag-line"
                  name="tagLine"
                  maxLength={tagLineMax}
                  onChange={handleChange}
                  value={formData.tagLine || ''}
                />
                <div className="char-remaining">
                  {tagLineMax - (formData.tagLine ? formData.tagLine.length : 0)}
                </div>
              </div>
              <div className="flex-row">
                <label htmlFor="description">Description</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('description')}
                  />
                  {tooltip === 'description' && (
                    <p className="tooltip shadow-lg">
                      Displays on your About tab. Max lines: 10
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <textarea
                  name="description"
                  maxLength={2000}
                  onChange={handleChange}
                  value={formData.description || ''}
                />
                <div className="char-remaining">
                  {descriptionMaxLength - (formData.description ? formData.description.length : 0)}
                </div>
              </div>
            </div>
          </div>
          <div className="settings-section">
            <div className="input-row">
              <label>Links</label>
            </div>
            <div className="links-container">
              {formData.userLinks.sort((a,b) => a.rank - b.rank).map((link, idx) => (
                <div key={link.key || link.id} className={link.rank}>
                  <DisplayLink
                    link={link}
                    index={idx}
                    onLinkTitleChange={onLinkTitleChange}
                    onLinkValueChange={onLinkValueChange}
                    toggleLink={toggleLink}
                    editLink={editLink}
                    removeLink={removeLink}
                    cancelEditLink={cancelEditLink}
                    dragStart={dragStart}
                    dragEnter={dragEnter}
                    drop={drop}
                  />
                </div>
              ))}
              <div className="flex-row new-link" onClick={addNewLink}>
                <FontAwesomeIcon icon={faCirclePlus} color="var(--light-blue)" />
                <h4 className="text-light-blue">Add a new link</h4>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button type="submit">Save</button>
          <button
            type="button"
            className="secondary-btn cancel-btn"
            onClick={cancelSettingsUpdate}
          >
            Cancel
          </button>
        </div>
      </form>
      <FormAlert alertState={alertState} width={450} />
    </div>
  );
};

const DisplayLink = ({ link, index, onLinkTitleChange, onLinkValueChange, toggleLink, removeLink, editLink, cancelEditLink, dragStart, dragEnter, drop }) => {
  const [canDrag, setCanDrag] = useState(false);

  const dragHandleEnter = () => {
    setCanDrag(true);
  };

  const dragHandleExit = () => {
    setCanDrag(false);
  };

  return !link.editMode ? (
    <div className="display-link" draggable={canDrag}
      onDragStart={(e) => dragStart(e, index)}
      onDragEnter={(e) => dragEnter(e, index)}
      onDragEnd={drop}
    >
      <div className="flex-row">
        <div className="grip" onMouseEnter={dragHandleEnter} onMouseLeave={dragHandleExit}>
          <FontAwesomeIcon icon={faGripVertical} size="2x" color="var(--light-blue)" />
        </div>
        <img className="icon" src={link.icon} />
        <label>{link.name}</label>
      </div>
      {link.custom_link ? (
        <div className="flex-row">
          <div className="icon-btn" onClick={() => editLink(index)}>
            <FontAwesomeIcon icon={faPenToSquare} color="var(--light-blue)" />
          </div>
          <div className="icon-btn" onClick={() => removeLink(index)}>
            <FontAwesomeIcon icon={faCircleXmark} color="var(--light-blue)" />
          </div>
        </div>
      ) : (
        <div className="flex-row">
          <ToggleSwitch
            name={link.name + "Show"}
            onChange={(event) => toggleLink(event.target.checked, index)}
            checked={link.enabled}
          />
          <label className="showLabel" htmlFor={link.name + "Show"}>Show in List</label>
        </div>
      )}
    </div>
  ) : (
    <div className="display-link edit-link">
      <div>
        <label>Link Title</label>
        <input
          onChange={(e) => onLinkTitleChange(e, index)}
          value={link.name}
          required
        />
      </div>
      <div>
        <label>URL</label>
        <input
          onChange={(e) => onLinkValueChange(e, index)}
          value={link.value}
          required
        />
      </div>
      <span className="cancel-edit" onClick={() => cancelEditLink(index)}>
        <FontAwesomeIcon icon={faTimes} size="lg" color="var(--light-blue)" />
      </span>
    </div>
  );
};

export default Personalization;
