import React from 'react';
import '../css/overlays.css';
import useAuth from '../hooks/useAuth';
import CopyButton from './copy-button';

const Overlays = () => {
  const { user } = useAuth();
  const baseURL = `${process.env.BASE_URL}/${user.username}`;
  const overlayLinks = [
    {
      overlay: 'TTS/Media Overlay',
      link: baseURL + '/tts',
    },
    {
      overlay: 'Event Overlay',
      link: baseURL + '/events',
    },
    {
      overlay: 'Goal Overlay',
      link: baseURL + '/goal',
    },
    {
      overlay: 'Subscription Goal Overlay',
      link: baseURL + '/subgoal',
    },
    {
      overlay: 'Follow Goal Overlay',
      link: baseURL + '/followgoal',
    },
    {
      overlay: 'Chat Overlay',
      link: baseURL + '/chat',
    },
    {
      overlay: 'View Count Overlay',
      link: baseURL + '/view-count',
    },
    {
      overlay: 'Emote Wall Overlay',
      link: baseURL + '/emote-wall',
    },
  ];

  return (
    <div className="overlays">
      <div className="overlay-links">
        {overlayLinks.map(({ overlay, link }, idx) => (
          <div className="link-container" key={overlay + String(idx)}>
            <div className="link">
              <h4>{overlay + ':'}</h4>
            </div>
            <div className="flex-row">
              <CopyButton text={link} />
              <a href={link}>{link}</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Overlays;
