import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import OtpInput from 'react-otp-input';
import '../../css/totp-dialog.css';

const TotpDialog = ({ open, onComplete, onClose, message, showTotpError, enableBackupCode = false }) => {
  //const [error, setError] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [totp, setTotp] = useState('');
  const [showBackupCodeInput, setShowBackupCodeInput] = useState(false);
  const [backupCode, setBackupCode] = useState('');

  useEffect(() => {
    if (showTotpError) {
      setTotp('');
    }
  }, [showTotpError]);

  useEffect(() => {
    setTotp('');
    setShowBackupCodeInput(false);
  }, [open]);

  const totpInputChange = async (totp) => {
    if (totp.length >= 6) {
      if (onComplete) {
        onComplete({totp});
        setTotp('');
      }
      else {
        setTotp(totp);
      }
    }
    else {
      setTotp(totp);
    }
  };

  const messageTextStyle = {
    color: 'black',
    textAlign: 'center',
  };

  const onBackupCodeChange = (event) => {
    setBackupCode(event.target.value);
  };

  const submitBackupCode = async () => {
    await onComplete({backupCode});
    setBackupCode('');
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
      setTotp('');
      setBackupCode('');
      setShowBackupCodeInput(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: 'totp-dialog' }}
    >
      <DialogTitle>
        <div className="dialog-title">
          Two-Factor Authentication
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="dialog-container">
          {message && (
            <DialogContentText style={messageTextStyle}>
              {message}
            </DialogContentText>
          )}
          {showBackupCodeInput ? (
            <DialogContentText style={{textAlign: 'center'}}>
              Input a backup code
            </DialogContentText>
          ) : (
            <DialogContentText style={{textAlign: 'center'}}>
              Input one-time passcode from your authenticator app
            </DialogContentText>
          )}
          <div style={{display: 'flex', justifyContent: 'center'}}>
          {showBackupCodeInput ? (
             <input type="text" placeholder="Backup code"
              value={backupCode} onChange={onBackupCodeChange} />
          ) : (
            <OtpInput
              onChange={totpInputChange}
              numInputs={6}
              renderSeparator={<span>-</span>}
              inputStyle="totp-input"
              inputType="number"
              value={totp}
              renderInput={(props) => <input { ...props } />}
              shouldAutoFocus={true}
            />
          )}
          </div>
          {showBackupCodeInput ? (
            <button className="primary-btn" onClick={submitBackupCode}>Submit</button>
          ) : (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <a onClick={() => setShowBackupCodeInput(true)}>
                I don&apos;t have access to my authenticator.
              </a>
            </div>
          )}
          {showTotpError && (
            <DialogContentText style={{color: 'red', textAlign: 'center'}}>
              Incorrect passcode. Try again.
            </DialogContentText>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TotpDialog;
