import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import FormAlert from './form-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QRCode from 'qrcode';
import '../css/dialogs.css';
import '../css/security-form.css';
import {
  faCheckSquare,
  faTimesSquare,
  faDownload,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import OtpInput from 'react-otp-input';
import TotpDialog from './dialogs/totp-dialog';
import CopyButton from './copy-button';
import SecurityQuestions from './security-questions';
import FadeLoader from 'react-spinners/FadeLoader';

const SecurityForm = ({ email }) => {
  const passcodeLength = 16;
  const [securitySettings, setSecuritySettings] = useState({
    totpSetup: false,
  });
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [formData, setFormData] = useState({
    emailPasscode: '',
  });
  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [totpSecret, setTotpSecret] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  // Setting up 2FA (QR code dialog)
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);
  // Delete 2FA
  const [unlinkTotpDialogOpen, setUnlinkTotpDialogOpen] = useState(false);
  const [unlinkTotpError, setUnlinkTotpError] = useState(false);
  // Backup Codes
  const [backupCodes, setBackupCodes] = useState([]);
  const [backupCodeDialogOpen, setBackupCodeDialogOpen] = useState(false);
  const [backupCodesLoading, setBackupCodesLoading] = useState(false);
  const backupCodesDownloaded = useRef(false);

  useEffect(() => {
    const getSecuritySettings = async () => {
      let response = await axios.get('/api/security-settings');
      setSecuritySettings({
        ...response.data
      });
    };
    getSecuritySettings();
  }, []);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const logoutAll = async () => {
    let result = confirm(
      'Terminate all sessions? You will also be logged out of your current session.'
    );
    if (result) {
      await axios.post('/api/logout-all');
      window.location.href = '/login';
    }
  };

  const linkAuthenticator = async () => {
    setEmailDialogOpen(true);
    await axios.post('/api/email-passcode', { purpose: 'setup two-factor authentication' });
  };

  const createFactor = async () => {
    try {
      if (formData.emailPasscode.length != passcodeLength) {
        return;
      }
      let response = await axios.post('/api/totp', {
        emailCode: formData.emailPasscode
      });
      let { uri, secret } = response.data;
      let qr = await QRCode.toDataURL(uri);
      setTotpSecret(secret);
      setQrCode(qr);
      setEmailDialogOpen(false);
      setQrDialogOpen(true);
      setEmailError(false);
    } catch (err) {
      if (err.response && err.response.data == 'Failed Passcode Verification') {
        setEmailError(true);
        setFormData({
          ...formData,
          emailPasscode: '',
        });
      }
      console.error(err);
    }
  };

  const qrDialogClose = () => {
    setQrDialogOpen(false);
  };

  const emailDialogClose = () => {
    setEmailDialogOpen(false);
  }

  const unlinkAuthenticator = async () => {
    if (window.confirm('Are you sure? Your account will be less secure.')) {
      setUnlinkTotpDialogOpen(true);
    }
  };

  const otpInputChange = async (totp) => {
    setOtp(totp);
    if (totp.length >= 6) {
      let response = await axios.post('/api/totp-confirm', { totp });
      let { status } = response.data;
      if (status === true) {
        setSecuritySettings({
          ...securitySettings,
          totpSetup: true,
        });
        setQrDialogOpen(false);
        setBackupCodeDialogOpen(true);
        getBackupCodes();
        triggerAlert('Successfully verified authenticator');
      }
      else {
        setOtpError(true);
        setOtp('');
      }
    }
  };

  const onUnlinkTotpDialogComplete = async ({totp}) => {
    try {
      await axios.delete('/api/totp', { data: { totp }});
      window.location.href = '/account-settings?tab=security';
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data == 'Failed 2-Factor Authentication') {
        setUnlinkTotpError(true);
      }
    }
  };

  const onUnlinkTotpDialogClose = () => {
    setUnlinkTotpDialogOpen(false);
    setUnlinkTotpError(false);
  };

  const getBackupCodes = () => {
    if (backupCodes.length == 0) {
      setBackupCodesLoading(true);
      axios.post('/api/totp/backup-codes')
        .then((response) => setBackupCodes(response.data))
        .finally(() => setBackupCodesLoading(false));
    }
    setBackupCodeDialogOpen(true);
  };

  const refreshBackupCodes = async () => {
    try {
      setBackupCodesLoading(true);
      setBackupCodeDialogOpen(true);
      let response = await axios.post('/api/totp/backup-codes/refresh');
      setBackupCodes(response.data);
    } catch (err) {
      console.error(err);
      triggerAlert('Could not refresh backup codes', false);
    } finally {
      setBackupCodesLoading(false);
    }
  };

  const downloadCodes = () => {
    const element = document.createElement('a');
    document.body.appendChild(element);
    const file = new Blob([backupCodes.join('\n')], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "powerchat-backup-codes.txt";
    element.click();
    element.parentNode.removeChild(element);
    backupCodesDownloaded.current = true;
  };

  const backupCodeDialogClose = () => {
    if (!backupCodesDownloaded.current) {
      alert('Copy or download backup codes before closing dialog.');
    } else {
      setBackupCodeDialogOpen(false);
      backupCodesDownloaded.current = false;
    }
  }

  return (
    <div className="form-container">
      <div className="form-section">
        <h4>
          Two-Factor Authentication: &nbsp;
          {securitySettings.totpSetup ? (
            <FontAwesomeIcon icon={faCheckSquare} />
          ) : (
            <FontAwesomeIcon icon={faTimesSquare} />
          )}
        </h4>
        {securitySettings.totpSetup ? (
          <button type="button" className="secondary-btn" onClick={unlinkAuthenticator}>
            Unlink 2FA
          </button>
        ) : (
          <button type="button" className="secondary-btn" onClick={linkAuthenticator}>
            Setup 2FA
          </button>
        )}
        <Dialog
          open={emailDialogOpen}
          onClose={emailDialogClose}
        >
          <DialogTitle>
            Two-Factor Authentication Setup
          </DialogTitle>
          <DialogContent>
            <div className="dialog-container temp-passcode">
              <DialogContentText>
                An email with a temporary passcode has been sent to {email}
              </DialogContentText>
              <input name="emailPasscode"
                type="text"
                placeholder="Enter passcode"
                onChange={handleChange}
                value={formData.emailPasscode}
                maxLength={passcodeLength}
                autoComplete="false"
              />
              <div className="flex-row">
                <button className={"primary-btn " + (formData.emailPasscode.length == passcodeLength ? '' : 'disabled')}
                  onClick={createFactor}
                >
                  Submit
                </button>
                <button className="secondary-btn" onClick={emailDialogClose}>
                  Cancel
                </button>
              </div>
              {emailError && (
                <DialogContentText style={{color: 'red'}}>
                  Could not verify. Try again.
                </DialogContentText>
              )}
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={qrDialogOpen}
          onClose={qrDialogClose}
          classes={{ paper: 'security-dialog totp-dialog' }}
        >
          <DialogTitle sx={{textAlign:'center'}}>
            Two-Factor Authentication Setup
          </DialogTitle>
          <DialogContent sx={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <DialogContentText>
              Scan with an authenticator app
            </DialogContentText>
            <img src={qrCode} />
            <DialogContentText sx={{marginBottom:"5px", color:'black'}}>
              <div className="note">Or, copy and paste into authenticator app</div>
              <CopyButton text={totpSecret} display={totpSecret} size="1x" />
            </DialogContentText>
            <DialogContentText>
              Input your one-time passcode
            </DialogContentText>
            <OtpInput
              shouldAutoFocus={true}
              onChange={otpInputChange}
              numInputs={6}
              renderSeparator={<span>-</span>}
              inputStyle="totp-input"
              inputType="number"
              value={otp}
              renderInput={(props) => <input { ...props } />}
            />
            {otpError && (
              <DialogContentText style={{color: 'red'}}>
                Could not verify. Try again.
              </DialogContentText>
            )}
          </DialogContent>
        </Dialog>
        <div className="note">
          Secure your account with an authenticator app (e.g. Google, Microsoft, or Authy).
        </div>
      </div>
      { securitySettings.totpSetup && (
        <div className="form-section">
          <h4>Backup Codes</h4>
          <button type="button" className="secondary-btn" onClick={refreshBackupCodes}>
            Refresh Backup Codes
          </button>
        </div>
      )}
      <Dialog
        open={backupCodeDialogOpen}
        onClose={backupCodeDialogClose}
        classes={{ paper: 'security-dialog' }}>
        <DialogTitle>
          <div className="dialog-title">
            Two-Factor Authentication - Backup Codes
            <IconButton
              aria-label="close"
              onClick={backupCodeDialogClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="dialog-container" style={{alignItems: 'center'}}>
            <div className="backup-codes">
            {backupCodes.map((code, idx) => (
              <div className="code-container" key={'backup-code-' + idx}>
                {code}
              </div>
            ))}
            </div>
            { backupCodesLoading ? (
              <FadeLoader color="black" loading={backupCodesLoading} size={20} />
            ) : (
              <div className="note">
                These will no longer be accessible after navigating away from this page.<br/>
                Download or copy/paste to save.
              </div>
            )}
            { !backupCodesLoading && (
              <div className="flex-row">
                <button className="secondary-btn" onClick={downloadCodes}>
                  Download&nbsp;
                  <FontAwesomeIcon icon={faDownload} size="1x" />
                </button>
                <CopyButton onClick={() => { backupCodesDownloaded.current = true; }}
                  text={() => backupCodes.join('\n')} display="Copy All" size="1x" />
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <div className="form-section">
        <button type="button" className="secondary-btn" onClick={logoutAll}>Logout All Sessions</button>
        <div className="note">
          Log your user out of all sessions (including this one).
        </div>
      </div>
      <div className="form-section">
        <h4>Security Questions</h4>
        <SecurityQuestions
          answers={securitySettings.answers}
          triggerAlert={triggerAlert}
        />
      </div>
      <TotpDialog
        message={'Authentication required for disabling 2-factor authentication'}
        open={unlinkTotpDialogOpen}
        onComplete={onUnlinkTotpDialogComplete}
        onClose={onUnlinkTotpDialogClose}
        showTotpError={unlinkTotpError}
      />
      <FormAlert alertState={alertState} width={400} />
    </div>
  );
};

export default SecurityForm;
