import React, { useState } from 'react';

const NumberInput = ({
  name,
  placeholder,
  value,
  minValue, // Optional
  maxValue, // Optional
  step,
  onValueChange,
  allowNegativeValue = true, // Optional
}) => {
  const [inputValue, setInputValue] = useState(value);
  
  const handleChange = (e) => {
    const { value, name } = e.target;
    setInputValue(value);
    onValueChange(value, name);
  };

  const handleBlur = (e) => {
    const { value, name } = e.target;
    if (value === '') {
      setInputValue('');
      onValueChange('', name);
    }

    if (minValue !== undefined && value < minValue) { // Check if minValue is provided
      setInputValue(minValue);
      onValueChange(minValue, name);
    }

    if (maxValue !== undefined && value > maxValue) { // Check if maxValue is provided
      setInputValue(maxValue);
      onValueChange(maxValue, name);
    }

    if (value < 0 && !allowNegativeValue) {
      setInputValue(0);
      onValueChange(0, name);
    }
  };

  return (
    <input
      type="number"
      name={name}
      placeholder={placeholder}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      min={minValue} // Will be ignored if not provided
      max={maxValue} // Will be ignored if not provided
      step={step}
      autoComplete="off"
    />
  );
};

export default NumberInput;
