import React from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faStar, faLandmark, faBrain, faMasksTheater, faBullhorn, faVolumeLow, faSliders, faVideo, faMars, faVenus } from '@fortawesome/free-solid-svg-icons';

const { Option, DropdownIndicator } = components;

const iconMapping = {
  'star': { icon: faStar, color: 'gold', label: 'Celebrity' },
  'political': { icon: faLandmark, color: 'red', label: 'Political' },
  'neural': { icon: faBrain, color: 'green', label: 'Neural' },
  'character': { icon: faMasksTheater, color: 'purple', label: 'Character' },
  'newscaster': { icon: faBullhorn, color: 'orange', label: 'Newscaster' },
  'whisper': { icon: faVolumeLow, color: 'gray', label: 'Whisper' },
  'pitch': { icon: faSliders, color: 'slategray', label: 'Pitch Altered' },
  'personality': { icon: faVideo, color: 'cyan', label: 'Personality' },
  'male': { icon: faMars, color: 'slateblue', label: 'Male' },
  'female': { icon: faVenus, color: 'hotpink', label: 'Female'},
};

const IconOption = (props) => {
  const iconInfo = iconMapping[props.data.icon] || {};
  const labelParts = props.data.label.split('\n').map((part, index) => (
    <div key={index}>{part}</div>
  ));
  const gradientStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start', // Align items to the left
    background: iconInfo.color
      ? `linear-gradient(90deg, ${iconInfo.color} 0%, rgba(255,255,255,0) 40%)`
      : 'none',
    width: '100%',
    padding: '10px', // Maintain some padding inside the option
  };

  return (
    <Option {...props}>
      <div style={gradientStyle} 
        title={`${props.data.label}\nThis is a ${iconInfo.label || 'default'} voice`}>
        {iconInfo.icon && (
          <FontAwesomeIcon 
            icon={iconInfo.icon} 
            style={{ marginRight: '0.5rem' }} 
          />
        )}
        {/* Render the label parts here */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {labelParts}
        </div>
      </div>
    </Option>
  );
};

// Custom Dropdown Indicator replacing with magnifying glass
const CustomDropdownIndicator = props => {
  return (
    <DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faSearch} />
    </DropdownIndicator>
  );
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    width: '100%',
    minWidth: '200px',
    marginTop: '10px',
    borderRadius: '4px', // Rounded edges for the select box
    borderColor: state.isFocused ? '#5bc7ec' : base.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #5bc7ec' : base.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? '#5bc7ec' : base.borderColor,
    }
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid lightgray',
    color: 'black',
    padding: 0,
    background: state.isFocused
      ? `linear-gradient(90deg, rgba(255,255,255,0) 0%, #5bc7ec 100%)`
      : 'none',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '4px', // Rounded edges for the dropdown menu
    marginTop: '0', // Align the menu right next to the select box
    // Custom scrollbar styles
    "::-webkit-scrollbar": {
      width: '8px',
    },
    "::-webkit-scrollbar-track": {
      background: '#d3d3d3',
    },
    "::-webkit-scrollbar-thumb": {
      background: '#5bc7ec',
      borderRadius: '4px',
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: 'rgba(87, 186, 216, 0.9)',
    }
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: '4px', // Rounded edges for the dropdown menu
    // Only show the vertical scrollbar when necessary
    overflowY: 'auto',
    // Remove horizontal scrollbar
    overflowX: 'hidden',
    // Custom scrollbar styles for Webkit browsers
    "::-webkit-scrollbar": {
      width: '8px',
    },
    "::-webkit-scrollbar-track": {
      background: '#d3d3d3',
      borderRadius: '4px',
    },
    "::-webkit-scrollbar-thumb": {
      background: '#5bc7ec',
      borderRadius: '4px',
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: 'rgba(87, 186, 216, 0.9)',
    },
  }),
};

const VoiceSelect = ({ name, options, value, onChange, placeholder }) => {
  return (
    <Select
      name={name}
      options={options}
      styles={customStyles}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      components={{ Option: IconOption, DropdownIndicator: CustomDropdownIndicator }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary25: '#5bc7ec', // Used for the option's background color when it is focused
          primary: '#5bc7ec', // Used for the border color when the select is focused
        },
      })}
    />
  );
};

export default VoiceSelect;
