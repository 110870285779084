import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import '../css/security-questions.css';

const SecurityQuestions = ({triggerAlert, answers}) => {

  const [questions, setQuestions] = useState([]);
  const [selections, setSelections] = useState([null,null,null]);

  useEffect(() => {
    const loadQuestions = async () => {
      let response = await axios.get('/pubapi/security-questions');
      setQuestions(response.data.map(x => ({
        label: x.question,
        value: x.id,
      })));
    };
    loadQuestions();
  }, []);

  useEffect(() => {
    if (answers && answers.length != 0 && selections && selections.length != 0) {
      setSelections(answers.map(a => ({
        answer: '',
        label: a.question,
        value: a.questionId,
        answerId: a.answerId,
        editMode: false,
      })));
    }
  }, [answers]);

  useEffect(() => {
    setQuestions(questions.map((q) => {
      if (selections.filter(s => s).map(s => s.value).includes(q.value)) {
        q.isDisabled = true;
      } else {
        q.isDisabled = false;
      }
      return q;
    }));
  }, [selections]);

  const changeSelection = (option, meta) => {
    let nextState = selections.map((s, i) => {
      if (meta.name == i) {
        return {
          ...option,
          answer: '',
          editMode: true,
        };
      } else {
        return s;
      }
    });
    setSelections(nextState);
  };

  const answerChange = (evt) => {
    let nextState = selections.map((s, i) => {
      if (evt.target.name == i) {
        return {
          ...s,
          answer: evt.target.value
        };
      } else {
        return s;
      }
    });
    setSelections(nextState);
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      width: '500px',
      height: '42px',
      margin: '10px 0 -2px 0',
      border: 'none',
      outline: 'none',
      whiteSpace: 'nowrap',
    }),
    option: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#ccc' : 'black',
    }),
    valueContainer: (styles) => ({
      ...styles,
      height: '42px',
      alignContent: 'center',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      height: '42px',
    }),
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post('/api/security-answers', selections.map(x => ({
      questionId: x.value,
      answer: x.answer,
      answerId: x.answerId,
    }))).then(() => triggerAlert('Questions saved successfully'))
    .catch((err) => {
      console.error(err);
      triggerAlert('Could not save questions', false)
    });
  };
  
  const inputClick = (event) => {
    let nextState = selections.map((s, i) => {
      if (event.target.name == i && !selections[i].editMode) {
        event.preventDefault();
        if (!confirm('Are you sure you want to edit this answer?')) {
          document.activeElement.blur();
          return s;
        }
        return {
          ...s,
          editMode: true,
        };
      } else {
        return s;
      }
    });
    setSelections(nextState);
  };

  return (
    <div className="form-container">
      <div className="security-questions-container">
      {[...new Array(3)].map((x, i) => (
        <div key={'selection-div-' + i}>
          <div style={{width:'500px'}}>
            <Select
              name={i}
              options={questions}
              styles={selectStyles}
              placeholder="Select a question"
              value={selections[i]}
              onChange={changeSelection}
              isSearchable={false}
            />
          </div>
          <input type="text" name={i}
            autoComplete="off"
            className={(selections[i] && !selections[i].editMode) ? 'no-edit' : ''}
            disabled={!selections[i]} onClick={inputClick}
            value={!selections[i] ? '' :
              (selections[i].editMode ? selections[i].answer : 'Edit')}
            onChange={answerChange} />
        </div>
      ))}
        <button type="submit" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default SecurityQuestions;
