import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/following.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Search from './search';

const Following = ({
    selfUsername,
    list,
    }) => {
  let tmp = list
          .map((x) => {
            return { ...x, opened: false, visible: true };
          });
  const [following, setFollowing] = useState(tmp);
  const [filteredFollowing, setFilteredFollowing] = useState(tmp);
  const [count, setCount] = useState(tmp.length);
  const removeFollowerSetup = (followerUsername,streamerUsername) => {
  // this function was originally defined in FollowButton
  // FollowButton was not working nicely when called within the Following
  // component, so a copy of the removeFollower function was placed here
    return (async () => {
      try{
        const options = {
            params:{
              streamerUsername: streamerUsername,
              followerUsername: followerUsername
            }
          } 
        await axios.post(`/api/followers/remove`,options)
      }catch(err){
        console.log(err);
        throw(err);
      }
    });
  }

  const toggleOpenFollower = (index) => {
    setFollowing((follows) => {
      follows[index]['opened'] = !follows[index]['opened'];
      return [...follows];
    });
  };
  const removeFromListSetup = (removeIndex,followerUsername,streamerUsername) => {
    const removeFollowerCallback = removeFollowerSetup(followerUsername,streamerUsername); 
    return () => {
      setFollowing(
        following.filter(
          (item,index) => {return index !== removeIndex; }
        )
      );
      removeFollowerCallback();
    }
  }
  useEffect(()=>{
    setFilteredFollowing(following);
    setCount(following.length);
  },[following.length])
  const searchFilterCallback = ({ username }, searchInput) =>
    username.toLowerCase().includes(searchInput);
  return (
        <div className="following">
          <h4>Following Total: {count}</h4>
          <Search
            inputPlaceholder="Filter by username"
            initialData={following}
            setData={setFilteredFollowing}
            filterCallback={searchFilterCallback}
          />
          <div className="following-mobile">
              {filteredFollowing
              .filter((x) => x.visible)
              .map((user, index) => (
                <div key={index} className="follower-container" id={"removeIndexMobile_"+index}>
                  <div
                    className="dropdown-toggle"
                    onClick={() => toggleOpenFollower(index)}
                  >
                    <div className="toggle-button">
                      {filteredFollowing[index].opened ? (
                        <FontAwesomeIcon icon={faChevronUp} size="lg" />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} size="lg" />
                      )}
                    </div>
                    <div className="title">{user.username}</div>
                  </div>
                  {filteredFollowing[index].opened && (
                    <table className="follower-info">
                    <tbody>
                      <tr>
                        <td>Username: <a href={"/"+user.username}>{user.username}</a></td>
                        <td rowSpan="2">
                        <button
                          className="unfollow-btn"
                          onClick={removeFromListSetup(index,selfUsername,user.username)}
                        >Unfollow</button>
                        </td>
                        </tr>
                        <tr>
                          <td>Follower since: {user.date_followed.substring(0,10)}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              ))
              }
          </div>
          <div className="following-table">
            <table>
              {filteredFollowing.length>0 && (
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <span>Username</span>
                    </th>
                    <th>
                      <span>Follower Since</span>
                    </th>
                    <th>
                      <span></span>
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {filteredFollowing
                  .filter((x) => x.visible)
                  .map(( user, index) => {
                  return (
                    <tr key={index} id={"removeIndexDesktop_"+index}>
                      <td className="crop-wrapper"><img src={user.avatar || `/static/img/user-default.jpg`} /></td>
                      <td><a href={"/"+user.username}>{user.username}</a></td>
                      <td>{user.date_followed.substring(0,10)}</td>
                      <td>
                        <button
                          className="unfollow-btn"
                          onClick={removeFromListSetup(index,selfUsername,user.username)}
                        >Unfollow</button>
                      </td>
                    </tr>
                  )}
                )}
                
              </tbody>
            </table>
            </div>
        </div>
      );
}

export default Following;
