import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../css/settings-form.css';
import '../css/payment-integrations.css';
import ToggleSwitch from './toggle-switch';
import FormAlert from './form-alert';
import TotpDialog from './dialogs/totp-dialog';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CoinSelection from './coin-selection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  faCheckSquare,
  faTimesSquare,
} from '@fortawesome/free-solid-svg-icons';

const PaymentIntegrations = ({ updateData, ...props }) => {
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [formData, setFormData] = useState({
    ...props,
    paypalEmail: props.paypalEmail || '',
    stripeLinked: !!props.stripeUserId,
    stripeEnabled: props.stripeEnabled,
    squareEnabled: props.squareEnabled,
    squareLinked: !!props.squareUserId,
    paypalLinked: !!props.paypalMerchantId,
    coinName: props.coinName || 'Litecoin',
    coinCode: props.coinCode || 'LTC',
    coingeckoId: props.coingeckoId || 'litecoin',
  });
  const twoFaSetup = props.twoFaSetup;
  const initialData = props;
  const [waitingSave, setWaitingSave] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [stripeExpressLoading, setStripeExpressLoading] = useState(false);
  const coinsLoaded = false;
  const [totpDialogOpen, setTotpDialogOpen] = useState(false);
  const [totpDialogMessage, setTotpDialogMessage] = useState('');
  const [totpError, setTotpError] = useState(false);
  const totpDialogCallback = useRef(null);
  const [coinOptions, setCoinOptions] = useState([]);
  const [coinDialogOpen, setCoinDialogOpen] = useState(false);
  const CRYPTO_DISPLAY_MIN = 0.000001;

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleToggle = (name) => {
    setFormData({
      ...formData,
      [name]: !formData[name],
    });
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const linkStripe = async () => {
    setStripeLoading(true);
    location.href = '/api/stripe/auth';
  };

  const createStripeExpress = async () => {
    setStripeExpressLoading(true);
    const response = await axios.post('/api/stripe/express');
    setStripeExpressLoading(false);
    location.href = response.data.url;
  };

  const manageStripeExpress = async () => {
    setStripeExpressLoading(true);
    location.href = '/api/stripe/express-dashboard';
  };

  const onUnlinkStripeClick = () => {
    let result = confirm(
      'Are you sure? Unlinking will cancel any recurring Stripe subscriptions to your account.'
    );
    if (!result) {
      return;
    }
    if (twoFaSetup) {
      setTotpDialogMessage('Authentication required for unlinking Stripe');
      totpDialogCallback.current = async (totp) => {
        try {
          await unlinkStripe(totp);
          totpDialogClose();
        } catch (err) {
          console.error(err);
          if (err.response && err.response.data == 'Failed 2-Factor Authentication') {
            setTotpError(true);
          }
        }
      };
      setTotpDialogOpen(true);
    }
  };

  const unlinkStripe = async (totp = null) => {
    setStripeLoading(true);
    await axios.delete('/api/stripe', { data: { totp }});
    setFormData({
      ...formData,
      stripeUserId: '',
      stripeLinked: false,
    });
    setStripeLoading(false);
    updateData({ stripeUserId: '' });
  };

  const linkSquare = async () => {
    location.href = '/api/square/auth';
  };

  const onUnlinkSquareClick = () => {
    if (twoFaSetup) {
      setTotpDialogMessage('Authentication required for unlinking Square');
      totpDialogCallback.current = async ({totp}) => {
        try {
          await unlinkSquare(totp);
          totpDialogClose();
        } catch (err) {
          console.error(err);
          if (err.response && err.response.data == 'Failed 2-Factor Authentication') {
            setTotpError(true);
          }
        }
      };
      setTotpDialogOpen(true);
    }
  };

  const unlinkSquare = async (totp = null) => {
    await axios.delete('/api/square', { data: { totp }});
    setFormData({
      ...formData,
      squareUserId: '',
      squareLinked: false,
    });
    updateData({ squareUserId: '' });
  };

  const linkPaypal = async () => {
    location.href = '/api/paypal/link';
  };

  const unlinkPaypal = async () => {
    await axios.delete('/api/paypal/link');
    setFormData({
      ...formData,
      paypalLinked: false,
    });
    updateData({ paypalMerchantId: '' });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setWaitingSave(true);
    try {
      // If user is changing their crypto address, ask for 2FA
      let hasNewAddress = !!formData.cryptoAddress && formData.cryptoAddress != '';
      let hasOldAddress = !!initialData.cryptoAddress && initialData.cryptoAddress != '';
      let addressChanged = formData.cryptoAddress != initialData.cryptoAddress
        && (hasNewAddress || hasOldAddress);
      let coinChanged = formData.coinCode != initialData.coinCode;
      if (addressChanged || coinChanged) {
        //verify address is valid
        if (hasNewAddress) {
          let verifyResponse = await axios.post('/api/verify-address', {
            address: formData.cryptoAddress,
            coin: formData.coinCode,
          });
          if (verifyResponse.data !== true) {
            triggerAlert(`Not a valid address for ${formData.coinCode}`, false);
            return;
          }
        }

        if (!twoFaSetup) {
          triggerAlert('Must have Two-Factor Authentication setup to change cryptocurrency address', false);
          return;
        }
        else {
          setTotpDialogMessage('Authentication required for changing cryptocurrency address');
          totpDialogCallback.current = async ({totp}) => {
            try {
              await savePaymentIntegrations(totp);
              totpDialogClose();
            } catch (err) {
              console.error(err);
              if (err.response && err.response.data == 'Failed 2-Factor Authentication') {
                setTotpError(true);
              }
            }
          };
          setTotpDialogOpen(true);
        }
      }
      else {
        savePaymentIntegrations();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setWaitingSave(false);
    }
  };

  const savePaymentIntegrations = async (totp = null) => {
    try {
      await axios.post('/api/payment-integrations', {
        paypalEmail: formData.paypalEmail,
        paypalEnabled: formData.paypalEnabled,
        stripeEnabled: formData.stripeEnabled,
        squareEnabled: formData.squareEnabled,
        cryptoEnabled: formData.cryptoEnabled,
        address: formData.cryptoAddress,
        instantAlerts: formData.cryptoInstantAlerts,
        coinName: formData.coinName,
        coinCode: formData.coinCode,
        coingeckoId: formData.coingeckoId,
        totp,
      });
      updateData(formData);
      triggerAlert('Payment settings successfully updated!');
    } catch (err) {
      if (err.response && err.response.data == 'Failed 2-Factor Authentication') {
        throw err;
      }
      console.error(err);
      triggerAlert('Could not update payment settings', false);
    }
  };

  const totpDialogClose = () => {
    setTotpDialogOpen(false);
    setTotpError(false);
  };

  useEffect(() => {
    const loadCoins = async () => {
      let response = await axios.get('/pubapi/crypto/coins');
      setCoinOptions(response.data.map(x => ({
        ...x,
        visible: true,
      })));

      //TODO uncomment after enabling payouts with other coins
      //setCoinsLoaded(true);
    };
    loadCoins();
  }, []);

  const openCoinDialog = () => {
    if (formData.cryptoEnabled && twoFaSetup) {
      setCoinDialogOpen(true);
    }
  }

  const onCoinSelected = (coin) => {
    setCoinDialogOpen(false);
    setFormData({
      ...formData,
      coinName: coin.name,
      coinCode: coin.code,
      coingeckoId: coin.coingeckoId,
      cryptoAddress: '',
    });
    updateData({
      coinName: coin.name,
      coinCode: coin.code,
      coingeckoId: coin.coingeckoId,
      cryptoAddress: '',
    });
  };

  const selectedCoin = () => ({
    name: formData.coinName,
    code: formData.coinCode,
  });

  const spinnerStyle = {
    margin: 10,
    verticalAlign: 'middle',
  };

  return (
    <div className="payment-integrations form-container settings-container">
      {process.env.STRIPE_ENABLED == 'true' && (
        <div className="form-section">
          <h4 className="text-light-blue">
            Stripe: &nbsp;
            {formData.stripeLinked && (formData.stripeExpress == null || formData.stripeExpress == 2) ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.stripeLinked ?
              formData.stripeExpress ? (
                <div>
                  <div className="radio-input">
                    <ToggleSwitch
                      name="stripeEnabled"
                      onChange={() => handleToggle('stripeEnabled')}
                      checked={formData.stripeEnabled}
                    />
                    <label htmlFor="stripeEnabled">Stripe Enabled</label>
                  </div>
                  <button className="secondary-btn" onClick={manageStripeExpress}>
                    {formData.stripeExpress == 2 ? 'Manage Stripe' : 'Setup Stripe'}
                  </button>
                  <span style={spinnerStyle}>
                    <ClipLoader color="#ffffff" loading={stripeExpressLoading} size={20} />
                  </span>
                  <div style={{margin:0}} className="note">
                    {formData.stripeExpress == 2
                      ? "Your Stripe Express account has been created. You&apos;re all set!"
                      : "Your Stripe Express account needs some info. Please complete setup."
                    }
                  </div>
                </div>
              ) : (
                <div>
                  <div className="radio-input">
                    <ToggleSwitch
                      name="stripeEnabled"
                      onChange={() => handleToggle('stripeEnabled')}
                      checked={formData.stripeEnabled}
                    />
                    <label htmlFor="stripeEnabled">Stripe Enabled</label>
                  </div>
                  <button className="secondary-btn" onClick={onUnlinkStripeClick}
                    disabled={!twoFaSetup}>
                    Unlink Stripe
                  </button>
                  <span style={spinnerStyle}>
                    <ClipLoader color="#ffffff" loading={stripeLoading} size={20} />
                  </span>
                  <div style={{margin:0}} className="note">
                    Your Standard Stripe account is currently linked. You&apos;re all set!
                  </div>
                  {!twoFaSetup && (
                    <div className="note" style={{marginBottom: 0, marginTop: 10}}>
                      <a href="/account-settings?tab=security">Two-Factor Authentication</a>
                      &nbsp;must be setup to unlink Stripe.
                    </div>
                  )}
                </div>
            ) : (
              <div>
                <div className="stripe-grid">
                  <label>Create a Stripe account:</label>
                  <button className="secondary-btn" onClick={createStripeExpress}>
                    Create Account
                  </button>
                  <span style={spinnerStyle}>
                    <ClipLoader color="#ffffff" loading={stripeExpressLoading} size={20} />
                  </span>
                  <label>Link an existing account:</label>
                  <button className="secondary-btn" onClick={linkStripe}>
                    Link an Account
                  </button>
                  <span style={spinnerStyle}>
                    <ClipLoader color="#ffffff" loading={stripeLoading} size={20} />
                  </span>
                </div>
                <div className="note" style={{marginTop: 5, marginBottom: 0}}>
                  You can start getting paid on Powerchat in minutes with Stripe.
                  Simply click on one of the two buttons above to create or link an account.
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="form-section">
        <h4 className="text-light-blue">
          Square: &nbsp;
          {formData.squareLinked ? (
            <FontAwesomeIcon icon={faCheckSquare} />
          ) : (
            <FontAwesomeIcon icon={faTimesSquare} />
          )}
        </h4>
        <div>
          {formData.squareLinked ? (
            <div>
              <div className="radio-input">
                <ToggleSwitch
                  name="squareEnabled"
                  onChange={() => handleToggle('squareEnabled')}
                  checked={formData.squareEnabled}
                />
                <label htmlFor="squareEnabled">Square Enabled</label>
              </div>
              <button className="secondary-btn" onClick={onUnlinkSquareClick}
                disabled={!twoFaSetup}>
                Unlink Square
              </button>
              {!twoFaSetup && (
                <div className="note">
                  <a href="/account-settings?tab=security">Two-Factor Authentication</a>
                  &nbsp;must be setup to unlink Square.
                </div>
              )}
            </div>
          ) : (
            <div>
              <button className="secondary-btn" disabled={!twoFaSetup} onClick={linkSquare}>
                Link Square
              </button>
              {!twoFaSetup && (
                  <div className="note">
                    <a href="/account-settings?tab=security">Two-Factor Authentication</a>
                    &nbsp;must be setup to link Square.
                </div>
              )}
            </div>
          )}
        </div>
        <div className="note">
          Note: The <b>&apos;Location Type&apos;</b> of your business should be set to&nbsp;
          <b>&apos;Mobile Location&apos;</b> under&nbsp;
          <a href="https://squareup.com/dashboard/locations">
            Square location settings
          </a>&nbsp;
          to hide your physical address.
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {props.paypalLegacy && (
          <div className="form-section">
            <h4 className="text-light-blue">
              Paypal
              {formData.paypalScrape &&
                (formData.paypalLinked ? (
                  <span>
                    : &nbsp;
                    <FontAwesomeIcon icon={faCheckSquare} />
                  </span>
                ) : (
                  <span>
                    : &nbsp;
                    <FontAwesomeIcon icon={faTimesSquare} />
                  </span>
                ))}
            </h4>
            <div className="radio-input">
              <ToggleSwitch
                name="paypalEnabled"
                onChange={() => handleToggle('paypalEnabled')}
                checked={formData.paypalEnabled}
              />
              <label htmlFor="paypalEnabled">Paypal Enabled</label>
            </div>
            {props.paypalScrape ? (
              <div>
                {formData.paypalLinked ? (
                  <button onClick={unlinkPaypal} className="secondary-btn">
                    Unlink Paypal
                  </button>
                ) : (
                  <button onClick={linkPaypal} className="secondary-btn">
                    Link Paypal
                  </button>
                )}
              </div>
            ) : (
              <div>
                <input
                  name="paypalEmail"
                  type="email"
                  placeholder="Enter your Paypal email"
                  onChange={handleChange}
                  className="top-input-margin"
                  value={formData.paypalEmail}
                  disabled={!formData.paypalEnabled}
                />
              </div>
            )}
          </div>
        )}
        <div className="form-section">
          <h4 className="text-light-blue">Cryptocurrency:</h4>
          <div className="radio-input">
            <ToggleSwitch
              name="cryptoEnabled"
              onChange={() => handleToggle('cryptoEnabled')}
              checked={formData.cryptoEnabled}
            />
            <label htmlFor="cryptoEnabled">Crypto Payments Enabled</label>
          </div>
          <h4>Balance: {formData.cryptoBalance > CRYPTO_DISPLAY_MIN ? formData.cryptoBalance : 0}</h4>
          <h4>Currency:
            <button type="button" disabled={!coinsLoaded || !formData.cryptoEnabled || !twoFaSetup}
              className="select-coin" onClick={openCoinDialog} title="Changing payout coin not available yet">
              {selectedCoin() && selectedCoin().name}
            </button>
          </h4>
          <div className="note">
            Users can donate to you with any supported cryptocurrency.<br/>
            Currently, you can only add a Litecoin address for payouts. Support for other coins will be added later.
            {/*TODO uncomment/*You will be paid out using this selected currency and address.*/}
          </div>
          <Dialog open={coinDialogOpen} onClose={() => setCoinDialogOpen(false)}
            classes={{ paper: 'crypto-dialog' }}>
            <DialogTitle>
              <div className="dialog-title">Select a Coin</div>
              <IconButton
                aria-label="close"
                onClick={() => setCoinDialogOpen(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="dialog-container">
                <CoinSelection
                  options={coinOptions}
                  onCoinSelected={onCoinSelected}
                  selectedCoin={selectedCoin()}
                />
              </div>
            </DialogContent>
          </Dialog>
          <input
            name="cryptoAddress"
            placeholder="Enter your cryptocurrency address"
            onChange={handleChange}
            className="top-input-margin"
            disabled={!formData.cryptoEnabled || !twoFaSetup}
            value={formData.cryptoAddress}
          />
          {!twoFaSetup && (
            <div className="note">
              <a href="/account-settings?tab=security">Two-Factor Authentication</a>
              &nbsp;must be setup to change your crypto address.
            </div>
          )}
          <div className="note">
            Payouts occur once daily and require a minimum balance of $20.00 USD.
          </div>
          <div className="radio-input">
            <ToggleSwitch
              name="cryptoInstantAlerts"
              onChange={() => handleToggle('cryptoInstantAlerts')}
              disabled={!formData.cryptoEnabled}
              checked={formData.cryptoInstantAlerts}
            />
            <label htmlFor="cryptoInstantAlerts">
              Play Alerts Instantly Before Transaction is Confirmed
            </label>
          </div>
          <button type="submit" disabled={waitingSave}>Save</button>
        </div>
      </form>
      <span style={spinnerStyle}>
        <ClipLoader color="#ffffff" loading={waitingSave} size={20} />
      </span>
      <TotpDialog
        message={totpDialogMessage}
        open={totpDialogOpen}
        onComplete={totpDialogCallback.current}
        onClose={totpDialogClose}
        showTotpError={totpError}
      />
      <FormAlert alertState={alertState} width={400} margin={0} />
    </div>
  );
};

export default PaymentIntegrations;
