import React, { useState, useRef, useEffect } from 'react';

const DEFAULT_HEX_COLOR = '#000000'; // fallback color for when value is 'transparent'

const ColorInput = ({ name, value, onValueChange, opacity = false }) => {

  const rgbaToHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
  };

  const parseColor = (colorValue) => {
    if (colorValue === 'transparent') {
      return {
        r: 0,
        g: 0,
        b: 0,
        a: 0
      };
    }

    const rgbaMatch = colorValue.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*(?:\.\d+)?))?\)/);
    if (rgbaMatch) {
      return {
        r: parseInt(rgbaMatch[1], 10),
        g: parseInt(rgbaMatch[2], 10),
        b: parseInt(rgbaMatch[3], 10),
        a: parseFloat(rgbaMatch[4] || "1")
      };
    } else if (colorValue.startsWith("#")) {
      const bigint = parseInt(colorValue.slice(1), 16);
      return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
        a: 1
      };
    }
    return null;
  };

  const parsedColor = parseColor(value);
  const initialHexColor = parsedColor ? rgbaToHex(parsedColor.r, parsedColor.g, parsedColor.b) : DEFAULT_HEX_COLOR;

  const [inputValue, setInputValue] = useState(initialHexColor);
  const [opacityValue, setOpacityValue] = useState(parsedColor ? parsedColor.a : 1);
  const colorInputRef = useRef();

  useEffect(() => {
    const updatedParsedColor = parseColor(value);
    setInputValue(updatedParsedColor ? rgbaToHex(updatedParsedColor.r, updatedParsedColor.g, updatedParsedColor.b) : DEFAULT_HEX_COLOR);
    setOpacityValue(updatedParsedColor ? updatedParsedColor.a : 1);
  }, [value]);

  const handleOpacityChange = (e) => {
    const { value } = e.target;
    setOpacityValue(parseFloat(value));

    if (value === "0") {
      onValueChange('transparent', name);
    } else if (value === "1") {
      onValueChange(inputValue, name);
    } else {
      const rgbaColor = `rgba(${parsedColor.r}, ${parsedColor.g}, ${parsedColor.b}, ${parseFloat(value).toFixed(2)})`;
      onValueChange(rgbaColor, name);
    }
  };

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setInputValue(newColor);
  
    const newParsedColor = parseColor(newColor);
    if (opacityValue === 0) {
      onValueChange('transparent', name);
    } else {
      const rgbaColor = `rgba(${newParsedColor.r}, ${newParsedColor.g}, ${newParsedColor.b}, ${opacityValue.toFixed(2)})`;
      onValueChange(rgbaColor, name);
    }
  };

  const handleColorInputChange = (e) => {
    const { value } = e.target;
    if (value === 'transparent' || value.startsWith('#') || value.toLowerCase().startsWith('rgba')) {
      onValueChange(value, name);
    }
  };

  const getDisplayValue = () => {
    if (!parsedColor) {
      return '';  // or return a default value if you prefer
    }

    if (opacityValue === 0 || parsedColor.a === 0) {
      return 'transparent';
    } else if (opacityValue === 1 || !opacity) {
      return inputValue;
    } else {
      return `rgba(${parsedColor.r}, ${parsedColor.g}, ${parsedColor.b}, ${parseFloat(opacityValue).toFixed(2)})`;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className="color-input-container">
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <label htmlFor={`${name}_colorPicker`}>
          <div
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: getDisplayValue(),
              border: '1px solid #ccc',
              cursor: 'pointer',
            }}
          ></div>
        </label>
        <input
          id={`${name}_colorPicker`}
          ref={colorInputRef}
          type="color"
          name={name}
          value={inputValue}
          onChange={handleColorChange}
          style={{
            position: 'absolute',
            width: '1px',
            height: '1px',
            opacity: 0,
            left: '12px',
          }}
        />
        <input
          type="text"
          name={name}
          value={getDisplayValue()}
          onChange={handleColorInputChange}
          style={{ marginLeft: '8px', zIndex: 1, width: '200px', fontFamily: 'monospace' }}
        />
        {opacity && (
          <div style={{ marginLeft: '8px', position: 'relative', width: 'calc(100% - 232px)' }}>
            <label style={{ display: 'block', textAlign: 'center' }}>
              Opacity: {Math.round(opacityValue * 100)}%
            </label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={opacityValue}
              onChange={handleOpacityChange}
              style={{
                display: 'block',
                width: 'calc(100% + 28px)',
                appearance: 'none',
                outline: 'none',
                background: 'transparent',
                position: 'relative',
                zIndex: 2,
                marginLeft: '-14px',
                marginTop: '-10px'
              }}
            />
            <div
              style={{
                position: 'relative',
                top: '-25px',
                left: '0px',
                marginRight: '-15px',
                height: '6px',
                marginTop: '-3px',
                background: 'linear-gradient(to right, rgba(91, 199, 236, 0), #5bc7ec)',
                borderRadius: '3px',
                zIndex: 1,
                border: '1px solid #282830',
                boxShadow:
                  'inset 0 1px 2px rgba(255, 255, 255, 0.8), ' +
                  'inset 0 -1px 1px rgba(0, 0, 0, 0.05)'
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorInput;
